import React from 'react'

export default function CirclesFilled({ className }) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="178" height="293" viewBox="0 0 178 293">
            <defs>
                <clipPath id="clip-path">
                    <rect width="178" height="293" fill="none" />
                </clipPath>
            </defs>
            <g id="Background_Dots_Top_Left" data-name="Background Dots Top Left" clip-path="url(#clip-path)">
                <g transform="translate(-320 -318)">
                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -318)">
                    <circle id="Ellipse_2-2" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -318)">
                    <circle id="Ellipse_2-3" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -318)">
                    <circle id="Ellipse_2-4" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -318)">
                    <circle id="Ellipse_2-5" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -318)">
                    <circle id="Ellipse_2-6" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-320 -288)">
                    <circle id="Ellipse_2-7" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -288)">
                    <circle id="Ellipse_2-8" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -288)">
                    <circle id="Ellipse_2-9" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -288)">
                    <circle id="Ellipse_2-10" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -288)">
                    <circle id="Ellipse_2-11" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -288)">
                    <circle id="Ellipse_2-12" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-320 -258)">
                    <circle id="Ellipse_2-13" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -258)">
                    <circle id="Ellipse_2-14" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -258)">
                    <circle id="Ellipse_2-15" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -258)">
                    <circle id="Ellipse_2-16" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -258)">
                    <circle id="Ellipse_2-17" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -258)">
                    <circle id="Ellipse_2-18" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-320 -228)">
                    <circle id="Ellipse_2-19" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -228)">
                    <circle id="Ellipse_2-20" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -228)">
                    <circle id="Ellipse_2-21" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -228)">
                    <circle id="Ellipse_2-22" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -228)">
                    <circle id="Ellipse_2-23" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -228)">
                    <circle id="Ellipse_2-24" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-320 -198)">
                    <circle id="Ellipse_2-25" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -198)">
                    <circle id="Ellipse_2-26" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -198)">
                    <circle id="Ellipse_2-27" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -198)">
                    <circle id="Ellipse_2-28" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -198)">
                    <circle id="Ellipse_2-29" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -198)">
                    <circle id="Ellipse_2-30" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-320 -168)">
                    <circle id="Ellipse_2-31" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -168)">
                    <circle id="Ellipse_2-32" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -168)">
                    <circle id="Ellipse_2-33" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -168)">
                    <circle id="Ellipse_2-34" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -168)">
                    <circle id="Ellipse_2-35" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -168)">
                    <circle id="Ellipse_2-36" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-320 -138)">
                    <circle id="Ellipse_2-37" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -138)">
                    <circle id="Ellipse_2-38" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -138)">
                    <circle id="Ellipse_2-39" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -138)">
                    <circle id="Ellipse_2-40" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -138)">
                    <circle id="Ellipse_2-41" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -138)">
                    <circle id="Ellipse_2-42" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-320 -108)">
                    <circle id="Ellipse_2-43" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -108)">
                    <circle id="Ellipse_2-44" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -108)">
                    <circle id="Ellipse_2-45" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -108)">
                    <circle id="Ellipse_2-46" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -108)">
                    <circle id="Ellipse_2-47" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -108)">
                    <circle id="Ellipse_2-48" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-320 -78)">
                    <circle id="Ellipse_2-49" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -78)">
                    <circle id="Ellipse_2-50" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -78)">
                    <circle id="Ellipse_2-51" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -78)">
                    <circle id="Ellipse_2-52" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -78)">
                    <circle id="Ellipse_2-53" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -78)">
                    <circle id="Ellipse_2-54" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-320 -48)">
                    <circle id="Ellipse_2-55" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-290 -48)">
                    <circle id="Ellipse_2-56" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-260 -48)">
                    <circle id="Ellipse_2-57" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-230 -48)">
                    <circle id="Ellipse_2-58" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-200 -48)">
                    <circle id="Ellipse_2-59" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
                <g transform="translate(-170 -48)">
                    <circle id="Ellipse_2-60" data-name="Ellipse 2" cx="6.5" cy="6.5" r="6.5" transform="translate(320 318)" fill="#242424" />
                </g>
            </g>
        </svg>
    )
}
