import React from "react"
import Layout from "../components/Layout"
import { ChevronDoubleDownIcon } from '@heroicons/react/solid'
import CirclesFilled from '../components/CirclesFilled'
import CirclesOutline from '../components/CirclesOutline'
import StoreButton from "../components/StoreButton"
import Seo from '../components/SEO'
import { StaticImage } from "gatsby-plugin-image"
import '../styles/global.css'

export default function Home() {
  return (
    <Layout>
      <Seo title="Home" />
      <div className="flex items-center max-w-7xl mx-auto min-h-60vh sm:min-h-40vh">
        <div className="flex flex-col p-4">
          <div className="text-ash-bright font-roboto font-bold text-3xl sm:text-5xl text-center filter drop-shadow-lg">
            A simpler way<br />
            to learn Balisong.
          </div>
          <div className="flex flex-col justify-items-center items-center mt-12 animate-bounce">
            <div className="text-ash-bright font-roboto font-medium text-md filter drop-shadow-lg">
              SCROLL
            </div>
            <ChevronDoubleDownIcon className="text-ash-bright h-5 w-5" />
          </div>
        </div>
      </div>

      <div className="flex items-center max-w-7xl mx-auto">
        <div className="relative flex flex-col items-center p-4">
          <CirclesFilled className="absolute top-10 left-20 sm:left-36 md:top-20 md:left-60 lg:top-20 lg:left-1/3 z-0" />
          <StaticImage
            src="../images/Phone-Screens.png"
            className="w-full md:w-3/4 lg:w-3/5 z-10"
            alt="App Previews"
            placeholder="blurred"
          />
          <CirclesOutline className="absolute bottom-10 right-0 sm:right-20 md:bottom-10 md:right-56 lg:bottom-24 lg:right-1/4 z-0" />
        </div>
      </div>

      <div className="flex flex-col max-w-7xl mx-auto text-center mt-10 p-4">
        <div className="text-ash-bright font-open-sans font-medium text-2xl">
          Easily discover tricks and<br />
          track your progress as you practice!
        </div>

        <div className="text-ash-bright font-open-sans font-regular text-xl mt-10">
          We got frustrated learning<br />
          so you don't have to.
        </div>

        <div className="mt-10">
          <a href="https://apps.apple.com/us/app/balisong-mastery/id1593134824" target="_blank">
            <StoreButton>
              <svg className="fill-current w-8 h-8 mr-2" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path></svg>
              <span>App Store</span>
            </StoreButton>
          </a>
        </div>

        <div className="mt-10">
          <a href="https://play.google.com/store/apps/details?id=net.everythingproject.balisongmastery" target="_blank">
            <StoreButton>
              <svg className="fill-current w-6 h-6 mr-2" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"></path></svg>
              <span>Google Play</span>
            </StoreButton>
          </a>
        </div>
      </div>
    </Layout>
  )
}
